import { IoBedOutline } from "react-icons/io5";

const BedIcon = ({ id, setSelectedBed, selectedBed }) => {
    return (
      <div
        className={`min-w-fit min-h-fit p-5 cursor-pointer border rounded ${
          selectedBed === id
            ? "border-primary-color border-2 drop-shadow-lg shadow-lg"
            : ""
        }`}
        onClick={() => setSelectedBed(id)}
      >
        <IoBedOutline
          className={`text-8xl ${
            selectedBed === id ? "text-primary-color" : ""
          } `}
        />
        <p>
          Bed ID <span className="text-primary-color">{id}</span>
        </p>
      </div>
    );
  };

  export default BedIcon;