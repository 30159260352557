import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import Dropzone from "./Dropzone";
import DropzoneImageUpload from "./DropzoneImageUpload";
import { useEffect } from "react";
import {toast } from "react-toastify";

const ViewDocument = ({
  documentUploadMutate,
  documentUploadLoading,
  setViewDocument,
  selectedData,
}) => {

  useEffect(() => {
    if (selectedData?.rentAgreementPath) {
      setCreateRentAgreement("1");
      setShowUploadRentAgreement(true);
    }
  }, [selectedData?.rentAgreementPath]); 

  const [enableEKyc, setEnableEKyc] = useState(
    selectedData?.isIdVerificationEnabled == "1" ? "1" : "0"
  );
  const [createRentAgreement, setCreateRentAgreement] = useState(
    selectedData?.createRentAgreement == "1" ? "1" : "0"
  );
  const [enablePoliceVerification, setEnablePoliceVerification] = useState(
    selectedData?.isPoliceVerificationEnabled == "1" ? "1" : "0"
  );
  const [showUploadRentAgreement, setShowUploadRentAgreement] = useState(
    selectedData?.createRentAgreement == "1"
  );
  const [enableOnlinePayment,setEnableOnlinePayment]=useState(selectedData?.isOnlinePaymentEnabled=="1"?"1":"0")


  const name = (name) => {
    if (!name) return "";
    const lowerCaseName = name.trim().toLowerCase();
    return lowerCaseName.length > 10 ? `${lowerCaseName.slice(0, 10)}...` : lowerCaseName;
  };

  const initialValues = {
    id: selectedData?.id || "",
    clientId: selectedData?.clientId || "",
  };

  const handleSelectUserAgreement = (value) => {
    setCreateRentAgreement(value);
    setShowUploadRentAgreement(value == "1");
  };

  const SubmitHandler = (values) => {
     if (createRentAgreement === "1") {
      if (!values.rentAgreement && !selectedData?.rentAgreementPath) {
          toast.error("Please upload the Rent Agreement.");
          return;
      }
      if (!values.ownerSignatureImage && !selectedData?.signaturePath) {
          toast.error("Please upload the Owner Signature.");
          return;
      }
      console.log(values)
  }
    const submissionValues = {
      ...values,
      enableEKyc,
      createRentAgreement,
      enablePoliceVerification,
      enableOnlinePayment,
      id: values.id,
      clientId: values.clientId,
    };

    console.log(submissionValues)
  
    //Call mutation function
    documentUploadMutate(submissionValues);
  };
  

  return (
    <Model width="w-11/12 max-w-lg" setOpenModel={setViewDocument}>
      <Title>Onboard Details</Title>

      {documentUploadLoading && <Loading />}

      {!documentUploadLoading && (
        <Formik
          initialValues={initialValues}
          onSubmit={SubmitHandler}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Wrapper>
                <InputGroup>
                  <Label htmlFor="enableEKyc">Enable eKyc</Label>
                  <FieldWrapper $select={true}>
                    <Select
                      value={enableEKyc}
                      onChange={(e) => setEnableEKyc(e.target.value)}
                      className="w-full h-full pl-2"
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="enablePoliceVerification">
                    Enable Police Verification
                  </Label>
                  <FieldWrapper $select={true}>
                    <Select
                      value={enablePoliceVerification}
                      onChange={(e) => setEnablePoliceVerification(e.target.value)}
                      className="w-full h-full pl-2"
                      name="isOnlinePaymentEnabled"
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="enableOnlinePayment">
                    Enable Online Payment
                  </Label>
                  <FieldWrapper $select={true}>
                    <Select
                      value={enableOnlinePayment}
                      onChange={(e) => setEnableOnlinePayment(e.target.value)}
                      className="w-full h-full pl-2"
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="createRentAgreement">
                    Create Rent Agreement
                  </Label>
                  <FieldWrapper $select={true}>
                    <Select
                      value={createRentAgreement}
                      onChange={(e) => handleSelectUserAgreement(e.target.value)}
                      className="w-full h-full pl-2"
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FieldWrapper>
                </InputGroup> 
                {showUploadRentAgreement && (
                  <InputGroup>
                    <Label htmlFor="rentAgreement">Upload Rent Agreement</Label>
                    <Dropzone
                      setUploadFile={(file) => (values.rentAgreement = file)}
                      componentFor="rentAgreement"
                    />
                  </InputGroup>
                )}
                   {selectedData?.rentAgreementPath && (
                  <InputGroup>
                    <div className="flex items-center mt-[-15px]"> 
                      <svg className="h-4 w-4 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                      <a
                        href={selectedData.rentAgreementPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-primary-color ml-1 text-sm"
                      >
                        {name(selectedData?.name)}/rentagreement.pdf
                      </a>
                    </div> 
                  </InputGroup>
                )}
                <InputGroup>
                  <Label htmlFor="ownerSignature">Upload Owner Signature</Label>
                  <div>
                    <DropzoneImageUpload
                      setUploadFile={(file) => (values.ownerSignatureImage = file)}
                      componentFor="signature"
                    />
                  </div>
                </InputGroup>

                {selectedData?.signaturePath && (
                  <InputGroup>
                    <div className="flex items-center mt-[-15px]"> 
                      <svg className="h-4 w-4 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                      </svg>
                      <a
                        href={selectedData.signaturePath}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-primary-color ml-1 text-sm"
                      > 
                        {name(selectedData?.name)}/signature.png
                      </a> 
                    </div> 
                  </InputGroup> 
                )}
              </Wrapper> 
              <BtnWrapper> 
                <SubmitBtn type="submit">Submit</SubmitBtn>
              </BtnWrapper>
            </Form>
          )}
        </Formik>
      )}
    </Model>
  ); 
};

const Wrapper = tw.div`grid gap-6 my-6 px-1`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
export default ViewDocument;
