import { Link } from "react-router-dom";
import Images from "../Images";

const NotfoundPage = () => {
  return (
    <main className="min-h-screen flex flex-col items-center justify-center">
      <img
        className="h-[400px] w-[400px] aspect-square"
        src={Images.NotFoundPage}
      />
      <Link to="/dashboard">
        <button className="bg-black text-white px-6 py-2 text-lg rounded-xl">
          Go back
        </button>
      </Link>
    </main>
  );
};
export default NotfoundPage;
