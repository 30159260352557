import React from 'react';
import Model from "../Model";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";

const Kyc = ({ setOpenKYCModel, selectedData }) => {

  const tenantName = (name) => {
    if (!name) return "";
    const lowerCaseName = name.toLowerCase();
    return lowerCaseName.length > 8 ? `${lowerCaseName.slice(0, 8)}...` : lowerCaseName;
  };
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setOpenKYCModel}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Kyc</Title>
        <SingleItem name={"Aadhar Number"} value={selectedData?.aadhaarNumber || "N/A"} />
        <SingleItem name={"Pan Number"} value={selectedData?.panNumber || "N/A"} />
        <SingleItem
          name="Rent Agreement"
          value={
            selectedData?.rentAgreementPath ? (
              <>
                <svg className="h-5 w-5 text-primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                <a
                  href={selectedData.rentAgreementPath}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline text-primary-color !w-[50px] ml-1"
                >
                   {tenantName(selectedData?.name)}/rentagreement.html
                </a>
              </>
            ) : "N/A"
          }
        />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name>: {value}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500 w-32`;

export default Kyc;
