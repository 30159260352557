import React from "react";
import tw from "tailwind-styled-components"; 
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  
  const getGender = (gender) => {
    if (gender === 1) {
      return 'Male';
    } else {  
      return 'Female';
    }
  };

  const getOccupation = (occupation) => {
    if (occupation == 1) {
      return 'Student';
    } else {  
      return 'Professional';
    }
  }
  
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
<Title><MdViewHeadline className="mt-1" />View Details</Title>
<SingleItem name={"Name"} value={selectedData?.tenantName} />
<SingleItem name={"Phone Number"} value={selectedData?.tenantMobile}/>
<SingleItem name={"Gender"} value={getGender(selectedData?.tenantGender) || "N/A"} />
<SingleItem name={"Occupation"} value={getOccupation(selectedData?.occupation) || "N/A"} />
<SingleItem name={"Date of Birth"} value={moment(selectedData?.dob).format("DD-MM-YYYY") || "N/A"} />
<SingleItem name={"Father's Name"} value={selectedData?.fatherName || "N/A"} />
<SingleItem name={"Property Name"} value={selectedData?.propName || "N/A"} />
<SingleItem name={"Email"} value={selectedData?.email || "N/A"} />
<SingleItem name={"Alternate Mobile"} value={selectedData?.alternateMobile || "N/A"} />
<SingleItem name={"Address"} value={selectedData?.address || "N/A"} />
<SingleItem name={"Created At"} value={moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A") || "N/A"} />
<SingleItem name={"Updated At"} value={moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A")  || "N/A"}/>
</Wrapper>
</Model> 
  ); 
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`; 

export default ViewDetailModel;  