import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import moment from "moment";
import Config, { PaginationLimit } from "../../Config";
import { MdModeEdit } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { useNavigate} from "react-router-dom";
import { FaMoneyCheck } from "react-icons/fa6";

const Table = ({
  ApiData,
  setSelectedData,
  setEditUser,
  setViewDetail,
  setOpenKYCModel,
  userType,
}) => {
  const [data, setData] = useState([ 
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const navigate = useNavigate(); 
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
  };

  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
  };

  const viewKyc = (item) => {
    setOpenKYCModel(true);
    setSelectedData(item);
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        tenantDetails : tenantDetails(item),
        getRentSecurity : getRentSecurity(item),
        getBedsFloors : getBedsFloors(item),
        moveInOutDate: moveInOutDate(item),
        status: combineStatusAndKyc(item),
        action: ActionBtns(item), 
      })); 

      setData(tempData);
    } 
  }, [ApiData]);

  const viewTranstion  = (item) => { 
    navigate(`/transactions/${item.tenantId}`);
  };

  const getTenantGender = (tenantGender) => {
    if (tenantGender === 1) {
      return 'Male';
    } else if (tenantGender === 2) {
      return 'Female';
    } else {
      return 'Both';
    }
  };

  const tenantDetails= (item) => {
    return (
      <div className="flex gap-2 items-center">
        <img src="/images/avatar.png" alt="Client" className="w-10 h-10 rounded-full overflow-hidden" />
          <div className="flex flex-col gap-[2px]">
          <p className="text-zinc-900 font-semibold w-[100px]">{item.tenantName}</p>
          <p className="text-blue-600">{getTenantGender(item.tenantGender)}</p>
          <p className="text-yellow-500 text-xs">#{item.mobile}</p> 
        </div>
      </div>
    ); 
  };   

  const getRentSecurity = (item) => {
    return (
      <div className="flex flex-col">
        <span>Rent: ₹{item.rent}</span> 
        <span>Security: ₹{item.security}</span>
      </div>
    );
  };
  
  const getBedsFloors = (item) => {
    return (
      <div className="flex flex-col">
        <span>
          Bed Id:      
            {item.bedId != null ? item.bedId : "N/A"}
          </span>
        <span>Floors: {item.floor != null ? item.floor : "N/A"}</span>
      </div>
    );
  };
  

  const moveInOutDate = (item) => {  
    return (
      <div className="flex flex-col">
        <span>M.In : {moment(item.moveInDate).format("DD-MM-YYYY")}</span>
        <span>M.Out : {item.moveOutDate ? moment(item.moveOutDate).format("DD-MM-YYYY") : "---"}</span>
      </div>
    );
  }; 

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <div className="flex gap-1">
          {userType != Config.userType.MARKETING && (
            <Btn title="Edit Customer" onClick={() => edit(item)}>
              <MdModeEdit className="text-gray-700" />
            </Btn>
          )}
          <Btn title="View Detail" onClick={() => viewDetail(item)}>
            <IoMdEye className="text-gray-700" />
          </Btn>
        {/* <Btn title="KYC" onClick={() => viewKyc(item)}>
          <HiClipboardDocumentList className="text-gray-700" />
        </Btn>
          {item.transactionCount > 0 && (
          <Btn  title="Transactions" onClick={() => viewTranstion(item)}>
            <FaMoneyCheck  className="text-gray-700" />
          </Btn>
          )} */}
      </div> 
      </ActionBtnWrapper>  
    );
  };

  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;;
    } else if (status === 2) {
      return <Requested>Requested</Requested>;
    } else if(status === 3) {
      return <Occupied>Occupied</Occupied>;
    }
    else if(status === 4) {
      return <Reserved>Moving Out</Reserved>;
    }
    else {
      return <Moving>Reserved</Moving>
    }
  };


  const getKycStatus = (tenantKycStatus) => {
    if (tenantKycStatus === 1) {
      return <KycPending title="KYC Pending">P</KycPending>;
    } else if (tenantKycStatus === 2) {
      return <IdUpload title="KYC ID Uploaded">IU</IdUpload>;
    } else if (tenantKycStatus === 3) {
      return <Personal title="KYC Pinfo Submitted">PS</Personal>;
    } else if (tenantKycStatus === 4) {
      return <Selfie title="KYC Selfie Uploaded">SU</Selfie>
    } else if (tenantKycStatus === 5) {
      return <RentAgreement title="KYC Rent Agreement">RA</RentAgreement>
    } else if (tenantKycStatus === 6) {
      return <Verification title="KYC Police Verification">PV</Verification>
    } else {
      return <Unknown title="KYC Unknown status">US</Unknown>;
    }
  };

  const combineStatusAndKyc = (item) => {
    const statusElement = getStatus(item.status);
    const kycElement = getKycStatus(item.tenantKycStatus);
    return (
      <div className="flex gap-1">  
        <span >{statusElement}</span>
        <span>{kycElement}</span>
      </div>
    );
  };
  
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Tenant Details",
          accessor: "tenantDetails",
        },
        {
          Header: "Rent/Security",
          accessor: "getRentSecurity",
        },
      ];
    }
    return [
      {
        Header: "Tenant Details",
        accessor: "tenantDetails",     
      },   
      {
        Header: "Rent/Security",
        accessor: "getRentSecurity",
      },
      {
        Header: "Beds/Floors", 
        accessor: "getBedsFloors",
      },
      {
        Header: "Move In/Out Date",
        accessor: "moveInOutDate",
      },
      {
        Header: "Status/KYC Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex},
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>  
        <Thead>   
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")} 
                  <span>  
                    {column.isSorted ? ( 
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th> 
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}> 
          {page.map((row, rowIndex) => { 
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return ( 
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (cellIndex === 0 || cellIndex === 1 && window.innerWidth < 768) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>Beds/Floors</strong> {data[rowIndex].getBedsFloors}
                        </li>
                        <li>
                          <strong>Move In/Out Date</strong> {data[rowIndex].moveInOutDate}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn> 
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const ActionBtnWrapper = tw.div`
  flex flex-col items-center w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0
`;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? 'border-b' : '')}`;
const Tr = tw.tr`${(props) => (props.hasData ? "border-b" : "")} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const IdUpload = tw.div`bg-green-100 text-green-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;
const Personal = tw.div`bg-orange-100 text-orange-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;
const Selfie =  tw.div`bg-lime-100 text-lime-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;
const RentAgreement = tw.div`bg-teal-100 text-teal-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;
const Verification = tw.div`bg-yellow-100 text-yellow-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;
const KycPending = tw.div`bg-blue-100 text-blue-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;
const Unknown = tw.div`bg-zinc-100 text-zinc-500 font-bold text-xs grid place-items-center rounded-md h-7 w-7 cursor-pointer`;

const Requested = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Occupied= tw.div`bg-orange-100 text-orange-700 text-xs border-orange-300 border w-24 h-7 flex items-center justify-center rounded`;
const Reserved = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded`;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
const Moving = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded`;



export default Table;
