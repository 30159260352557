import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import moment from "moment";

const EditOccupancyModel = ({
  editOccupancyMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const InitialValues = {
    id: selectedData?.id,
    tenantId: selectedData?.tenantId,
    rent: selectedData?.rent,
    security: selectedData?.security,
    moveOutDate: selectedData?.moveOutDate,
    status: selectedData?.status,
    kycstatus: selectedData?.tenantKycStatus,
  };

  const today = moment().format("YYYY-MM-DD");
  const [status, setStatus] = React.useState(selectedData?.status);
  const [kycStatus, setKycStatus] = React.useState(selectedData?.tenantKycStatus);

  const SubmitHandler = (values) => {
    if (status !== "5") {
      delete values.moveOutDate;
    }
    editOccupancyMutate(values);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Occupancy</Title> 
      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler} enableReinitialize>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="rent">Rent</Label>
                  <FieldWrapper className="">
                    <Field 
                       type="text"
                       name="rent"
                       id="rent"
                       autoComplete="off"
                       className=""
                       required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="security">Security</Label>
                  <FieldWrapper className="">
                    <Field
                       type="text"
                       name="security"
                       id="security"
                       autoComplete="off"
                       className=""
                       required
                    />
                  </FieldWrapper>
                </InputGroup> 
              </CombineInputGroup>

               <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="status" id="status" autoComplete="off">
                      {(props) => (
                      <Select
                      className="w-full h-full pl-2"
                      {...props.field}
                      onChange={(e) => {
                        props.form.setFieldValue("status", e.target.value);
                        setStatus(e.target.value);
                      }}
                    > 
                      <MenuItem value="1">Pending</MenuItem>
                      <MenuItem value="2">Requested</MenuItem>
                      <MenuItem value="3">Occupied</MenuItem>
                      <MenuItem value="4">Reserved</MenuItem>
                      <MenuItem value="5">Moving Out</MenuItem>
                    </Select>
                      )}  
                    </Field>
                  </FieldWrapper> 
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="kycstatus">KYC Status</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="kycstatus" id="kycstatus" autoComplete="off">
                      {(props) => (
                      <Select
                      className="w-full h-full pl-2"
                      {...props.field}
                      onChange={(e) => {
                        props.form.setFieldValue("kycstatus", e.target.value);
                        setKycStatus(e.target.value);
                      }}
                    >
                      <MenuItem value="1">Pending</MenuItem>
                      <MenuItem value="2">ID Upload</MenuItem>
                      <MenuItem value="3">Personal Information</MenuItem>
                      <MenuItem value="4">Selfi Upload</MenuItem>
                      <MenuItem value="5">Rent Agreement</MenuItem>
                      <MenuItem value="6">Police Verification</MenuItem>
                    </Select>
                    
                      )}  
                    </Field>
                  </FieldWrapper> 
                </InputGroup>
                {status === "5" && (
              <InputGroup>
                  <Label htmlFor="moveout date">Moveout Date</Label>
                  <FieldWrapper className="bg-gray-100">
                    <Field
                      type="date"
                      name="moveOutDate" 
                      id="moveOutDate"
                      autoComplete="off"
                      className="truncate"
                      max={today}
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                )} 
            </Wrapper>
            <BtnWrapper> 
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model> 
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;
const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
export default EditOccupancyModel;