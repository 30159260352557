import React from 'react';
import Model from "../Model";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
const Kyc = ({ setOpenKYCModel, selectedData }) => {
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setOpenKYCModel}>
       <Wrapper>
  <Title><MdViewHeadline className="mt-1" />View Kyc</Title>
  <SingleItem name={"Aadhar Number"} value= {selectedData?.aadhaarNumber|| "N/A"} />
  <SingleItem name={"Pan Number"} value= {selectedData?.panNumber|| "N/A"} />
</Wrapper> 
    </Model>
  );
}
const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name>: {value}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
export default Kyc;
 