import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";

const EditOccupancysModel = ({
  editComplaintsMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const InitialValues = {
    status: selectedData?.status,
    id: selectedData?.id,
  };

  const SubmitHandler = (values) => {
    editComplaintsMutate(values);
  }
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Update Complaint Status</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler} enableReinitialize>
          <Form>
            <Wrapper>
               <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="status" id="status" autoComplete="off">
                      {(props) => (
                        <Select
                          className="w-full h-full pl-2"
                          {...props.field}
                        > 
                          <MenuItem value="1">Pending</MenuItem>
                          <MenuItem value="2">Resolve</MenuItem>
                          <MenuItem value="3">Inprogress</MenuItem>
                        </Select>
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model> 
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;
const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;
export default EditOccupancysModel;
