import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useLocation } from "react-router-dom";
import NavFloatingMenu from "./NavFloatingMenu";
import DownloadModel from "./DownloadModel";
import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const isAdmin = user?.userType == 1 ? "ADMIN" : "CUSTOMER";
  const [isNavActive, setIsNavActive] = useState(false);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [isMobileUser, setIsMobileUser] = useState(false);
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const logout = () => dispatch(Remove_User());
  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setIsMobileUser(isMobile);
  }, [window.innerWidth]);

  useEffect(() => {
    setIsNavActive(false);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  const toggleNavbar = () => setIsNavActive(!isNavActive);

  return (
    <>
      {openDownloadModel && (
        <DownloadModel
          setOpenDownloadModel={setOpenDownloadModel}
        />
      )}
      <Nav $hasScrolled={hasScrolled}>
        <Container $isNavActive={isNavActive}>
          <Link to="/" className="z-10">
            <div className="flex items-center ">
              <Logo src={Images.Logo} alt="logo" />
            </div>
          </Link>
          <MenuIconComp toggleNavbar={toggleNavbar} />
          <NavLinksComp isAdmin={isAdmin} isNavActive={isNavActive} isUserMenuVisible={isUserMenuVisible} setIsUserMenuVisible={setIsUserMenuVisible} isMobileUser={isMobileUser} logout={logout} user={user} pathname={pathname} setOpenDownloadModel={setOpenDownloadModel} />
        </Container>
      </Nav>
    </>
  );
};

const NavLinksComp = ({ isNavActive, setIsUserMenuVisible, isUserMenuVisible, isMobileUser, logout, user, pathname, }) => {
  const { t, i18n } = useTranslation();

  return (
    <NavLinksWrapper $isNavActive={isNavActive}>
      <Link to="/dashboard">
        <NavLink $active={pathname === "/dashboard"}>
          <p>{t("Dashboard")}</p>
        </NavLink>
      </Link>

      <Link to="/owners">
        <NavLink $active={pathname === "/owners"}>
          <p>{t("Owners")}</p>
        </NavLink>
      </Link>

      <Link to="/tenants">
        <NavLink $active={pathname === "/tenants"}>
          <p>{t("Tenants")}</p>
        </NavLink>
      </Link>

      <Link to="/complaints">
        <NavLink $active={pathname === "/complaints"}>
          <p>{t("Complaints")}</p>
        </NavLink>
      </Link>

      {!isMobileUser && <DesktopMenu setIsUserMenuVisible={setIsUserMenuVisible} isUserMenuVisible={isUserMenuVisible} logout={logout} user={user} isMobileUser={isMobileUser} />}
      {isMobileUser && <MobileMenu logout={logout} user={user} />}
    </NavLinksWrapper>
  );
};

const DesktopMenu = ({ setIsUserMenuVisible, isUserMenuVisible, logout, user, isMobileUser }) => (
  <NavLink $last={true} className="md:h-16 md:bg-transparent" onMouseEnter={() => setIsUserMenuVisible(true)} onMouseLeave={() => setIsUserMenuVisible(false)} onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}>
    <UserImage src={user?.userImage || Images.NurseImage} alt="nurse image" />
    <p className="md:hidden">My Profile</p>
    {isUserMenuVisible && <NavFloatingMenu setIsUserMenuVisible={setIsUserMenuVisible} logout={logout} />}
  </NavLink>
);

const MobileMenu = ({ logout, user }) => (
  <>
    <NavLink $last={true} title="logout">
      <Logout onClick={logout}>Logout</Logout>
    </NavLink>
  </>
);

const MenuIconComp = ({ toggleNavbar }) => (
  <MenuIcon onClick={toggleNavbar}>
    <div className="bar one"></div>
    <div className="bar two"></div>
    <div className="bar three"></div>
  </MenuIcon>
);


const Nav = tw.nav`
  z-50 w-full border-b fixed top-0 right-0 left-0
  ${(p) => (p.$hasScrolled ? 'bg-white' : 'bg-transparent')} transition-colors duration-300 ease-in-out
`;

const MenuIcon = tw.div`cursor-pointer z-10 md:hidden`;

const Container = tw.div` 
Container flex items-center justify-between`;

const Logo = tw.img`w-16 z-10`;

const NavLinksWrapper = tw.ul`
${(p) => (p.$isNavActive ? "translate-y-0" : "-translate-y-full")}
fixed  shadow-md top-0 right-0 left-0 flex flex-col bg-white pt-20 transition duration-200 pb-5  md:relative  md:translate-y-0 md:shadow-none md:flex-row   md:bg-transparent md:p-0   md:items-center w-full md:justify-end md:h-full`;

const NavLink = tw.li` 
${(p) => (p.$active ? "md:bg-[#0078bd] text-white hover:bg-[#81b7ed]" : "text-zinc-800 hover:bg-[#81b7ed]")} 
text-sm  relative flex space-x-1.5 items-center cursor-pointer w-full  py-3 px-4 h-9 rounded-full hover:bg-[#81b7ed] hover:text-white  whitespace-nowrap md:w-auto md:h-full ml-1 transition-colors duration-300 ease-in-out `;

const UserImage = tw.img`
w-6 h-6  md:w-8 md:h-8 rounded-full object-cover overflow-hidden bg-gray-100
`;

const Logout = tw.p` 
text-red-500 text-sm cursor-pointer`;

export default Navbar;
