import React, { useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import Loading from "react-loading";
import {
  Save_User,
} from "../Redux/actions";
import { useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { useMutation } from "react-query";

import {
  SubmitBtn,
  InputGroup,
  FieldWrapper,
  Label,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const InitialValue = {
    phone: "",
    password: "",
  };

  //------- Send Login Request -------
  const LoginPostFunction = async (values) => {
    const body = new FormData();
    body.append("username", values.username);
    body.append("password", values.password); 
    return await axios.post(`${Config.apiUrl}/login`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
      const expireAt = moment()
        .add(Config.sessionExpiredTime, "minutes")
        .valueOf();
      const realData = { ...res.data.data, expireAt };
      dispatch(Save_User(realData));
      navigate("/owners");
    } else toast.error(res?.data?.msg || "Error"); 
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postLogin } = useMutation(
    LoginPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
  //------- Send Login Request -------
  const SubmitHandler = (values) => {
    console.log(values);
    if (!values.username) {
      return toast.error("Please enter phone number");
    } else if (!values.password) {
      return toast.error("Please enter password");
    }
    postLogin({ ...values });
  };

  const handleLogin = () => {};

  return (
    <Logins> 
      <TextWrapperComp
        handleLogin={handleLogin}
        InitialValue={InitialValue}
        SubmitHandler={SubmitHandler}
        isLoadingReg={isLoadingReg}
        t={t}
      />
    </Logins>
  );
};

const TextWrapperComp = ({ 
  InitialValue,
  SubmitHandler, 
  t,
  isLoadingReg,
}) => (
  <TextWrapper>
    <Container>
      <Center>
        <div className="flex space-x-6">
          <Logo src={Images.Logo} alt="logo" />
        </div>
        <Title>{"Manage your Property"}</Title> 
        <SubTitle>{t("please_login")}</SubTitle>
        <Formik 
          initialValues={InitialValue}
          onSubmit={SubmitHandler} 
        >
          {(formikProps) => (
            <>
              <Form className="w-full">
                <FormContainer>
                  <InputGroup>
                    <Label htmlFor="username">{"User Name"}</Label>
                    <FieldWrapper>
                      <Field
                        name="username"
                        id="username"
                        type="text"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="password">{t("Password")}</Label>
                    <FieldWrapper>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <RowWrapper>
                    <Checkbox
                      icon={<BsCheckLg className="text-cyan-600 w-5 h-5" />}
                      name="my-input"
                      onChange={(value) => {
                      }}
                      borderColor="#6B7280"
                      style={{ cursor: "pointer" }}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: "none",
                        color: "#6B7280",
                        fontSize: ".875rem",
                      }}
                      label={t("rememeber")}
                    />
                  </RowWrapper>
                </FormContainer>

                <div className="mt-4 lg:mt-10 flex"> 
                  <SubmitBtn type="submit" disabled={isLoadingReg}>
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("login")
                    )}
                  </SubmitBtn> 
                </div>
              </Form>
            </>
          )} 
        </Formik>
      </Center>
    </Container>
  </TextWrapper>
); 

const FormContainer = tw.div` grid max-w-sm  mt-8 w-full gap-5 md:gap-5`;
const Logins = tw.section`h-screen flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col h-full w-full justify-center items-start   `;
const Logo = tw.img`w-20 h-20 object-contain mb-10`;
const TextWrapper = tw.div`h-full relative`;
const Title = tw.h1`text-xl sm:text-2xl font-light text-gray-800 tracking-wider`;
const SubTitle = tw.h3`mt-1 sm:mt-2 tracking-wider  text-gray-500 text-sm `;

const Button = tw.button` mt-10 h-20 w-96  md:w-72 lg:w-96 px-5   text-gray-800 flex items-center border rounded-md bg-white z-10 loginBtn  `;

const ImageWrapper = tw.div`hidden h-full  md:block md:w-1/2  `;
const LoginImage = tw.img`h-full w-full object-cover object-center`;

const Center = tw.div`h-full w-full  py-10 pt-20 mb-5  flex flex-col`;

const SmallText = tw.p`text-xs font-normal text-gray-400 `;

const VerticalBorder = tw.div`w-0.5 h-14 bg-gray-300 mt-4`;  

const LanguageWrapper = tw.div`absolute top-5 right-5 flex space-x-2.5 items-center z-50`;

const RowWrapper = tw.div`flex space-x-2.5 items-center z-50 mt-2 mb- justify-between w-full`;
const ForgotPassword = tw.h3`tracking-wider  text-cyan-600 text-sm  float-right`;

const LangButton = tw.button`  
${(p) =>
  p.$active
    ? "bg-cyan-600 text-white"
    : "hover:bg-gray-300 bg-gray-200 text-gray-800"}
px-4 py-2 text-xs  cursor-pointer  rounded-full `;

export default Login;
