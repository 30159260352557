import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { 
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Model from "../Model";

const availableAmenities = [
  { label: "Balcony", value: "balcony" },
  { label: "Ac", value: "ac" },
  { label: "Tv", value: "tv" },
  { label: "Attached Bathroom", value: "attached bathroom"},
  { label: "Wi-Fi", value: "wi-fi" },
  { label: "Washing Machine", value: "washing machine"},
];

const EditFloors = ({
  editOwnerMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  console.log("item" + selectedData);

  const amenitiesArray = selectedData?.amenities
    ? selectedData.amenities.split(",").map((amenity) => ({
        label: amenity.trim(),
        value: amenity.trim().toLowerCase(),
      }))
    : [];

  const animatedComponents = makeAnimated();

  const InitialValues = {
    room: selectedData?.room,
    amenities: amenitiesArray,
    id: selectedData?.id,
    floor: selectedData
  };

  const SubmitHandler = (values) => {
    const formattedValues = {
      ...values,
      amenities: values.amenities.map((item) => item.label).join(", "),
    };
    editOwnerMutate(formattedValues);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Room Options</Title>
      <hr className="text-gray-600"/>
      <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium mt-3">
        Maximum Lifetime
      </h2>
      <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-3">Set number of room to be increased or decrease in the floor</p>
      {editEntryLoading && <Loading />}
      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler} enableReinitialize>
          {({ values, setFieldValue }) => {

            // Dynamically filter available amenities based on current selections
            const filteredAmenities = availableAmenities.filter(
              (amenity) => !values.amenities.some((selected) => selected.value === amenity.value)
            );
            return (
              <Form>
              <Wrapper>
              <div className="flex items-center justify-between text-center w-[250px]">
                {/* <Btn title="increasing">
              <FaPlus className="text-gray-500"/>
            </Btn> */}
            <div className="h-10 w-40 border border-gray-300 bg-gray-100 text-gray-800 rounded-md">           
                  <Field
                    type="number"
                    name="room"
                    id="room"
                    autoComplete="off"
                    className="truncate !w-40"
                    required
                  /> 
               </div> 
            {/* <Btn title="decreasing">
              <FaMinus className="text-gray-500"/>
            </Btn> */}
            </div>
            </Wrapper>
            <hr className="mt-5 text-gray-600 mb-3"/>
  <div className="flex items-center justify-between">
    <h2 className="text-sm md:text-lg lg:text-lg text-gray-600 font-medium">Replicate</h2>
    <label className="inline-flex items-center cursor-pointer">
    <input type="checkbox" value="" className="sr-only peer" />
    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-color"></div>
  </label>
  </div>
  <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-1">
      Set if you want to increase or decrease room to every floor
  </p>

                <BtnWrapper>
                  <SubmitBtn type="submit">Update</SubmitBtn>
                </BtnWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </Model>
  );
};
const Wrapper = tw.div``;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

export default EditFloors;
