import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export function CustomerChart({data}) {
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
      if (data) {
        setFilteredData(data?.data?.data);
      }
    }, [data?.data?.data]);
  
    const labels = filteredData?.customer
      ? filteredData.customer.map((item) => item.date)
      : [];
const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },},
  plugins: {
    legend: {
      position: 'bottom' ,
    },
    title: {
      display: true,
      text: 'Customer & Payment Count',
    },
   
  },
};



 const BarData = {
  labels:labels,
  datasets: [
    {
      label: 'Customer',
      data: filteredData?.customer
      ? filteredData.customer.map((item) => item.customer)
      : [],
      backgroundColor: 'rgb(34, 197, 94)',
      borderRadius: 10, 
    },
    {
      label: 'Payment',
     
      data: filteredData?.customer
      ? filteredData.customer.map((item) => item.payment)
      : [],
      backgroundColor: 'rgb(14, 165, 233)',
      borderRadius: 10, 
    },
  ],
};


  return <Bar options={options} data={BarData} />;
}
