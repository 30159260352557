

import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);



// ChartJS.register(centerTextPlugin);

export function PieChart({ data }) {
    const [filteredData, setFilteredData] = useState({});

    useEffect(() => {
        if (data) {
            setFilteredData(data?.data?.data);
        }
    }, [data?.data?.data]);

    const labels = filteredData?.paymentsCount
        ? filteredData.paymentsCount.map((item) => item.name)
        : [];

    const pieData = {
        labels: labels,
        datasets: [
            {
                label: 'Count',
                data: filteredData?.paymentsCount
                    ? filteredData.paymentsCount.map((item) => item.count)
                    : [],
                backgroundColor: [
                    'rgb(14, 165, 233)',
                    'rgb(107, 114, 128)',
                ],
                borderColor: [
                    'rgb(14, 165, 233,1)',
                    'rgb(107, 114, 128,1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            // legend: {
            //     position: 'bottom',
            // },
            legend: {
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  generateLabels: function(chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                      return data.labels.map((label, i) => {
                        const meta = chart.getDatasetMeta(0);
                        const ds = data.datasets[0];
                        const arc = meta.data[i];
                        const value = ds.data[i];
                        const labelCount = filteredData.paymentsCount[i].count;
                        return {
                          text: `${label} - ${labelCount}`,
                          fillStyle: ds.backgroundColor[i],
                          strokeStyle: ds.borderColor[i],
                          lineWidth: ds.borderWidth,
                          hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                          index: i
                        };
                      });
                    }
                    return [];
                  }
                }
              },
            title: {
                display: true,
                text: 'Payment Status',
                position: 'bottom',
            },
        },
        cutout: '70%',
    };

    const centerTextPlugin = {
        id: 'centerText',
        beforeDraw: (chart) => {
            const { ctx, chartArea } = chart;
            const { width, height } = chartArea;
    
            ctx.restore();
            const fontSize = (height / 114).toFixed(2);
            ctx.font = `${fontSize}em sans-serif`;
            ctx.textBaseline = 'middle';
    
            const total = chart.data.datasets.reduce((acc, dataset) => {
                return acc + dataset.data.reduce((dAcc, d) => dAcc + d, 0);
            }, 0);
    
            const text = total.toLocaleString();
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            const textY = height / 2;
    
            ctx.fillStyle = '#000';
            ctx.fillText(text, textX, textY);
    
            ctx.save();
        },
    };
    return <Doughnut options={options} data={pieData}  plugins={[centerTextPlugin]}/>;
}
