import React, { useState, useEffect } from "react";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

export function GenderChart({ data }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data?.data);
    }
  }, [data?.data?.data]);

  const labels = filteredData?.gender
    ? filteredData.gender.map((item) => item.name)
    : [];

    const options = {
        responsive: true,
        plugins: {
            // legend: {
            //     position: 'bottom',
            //     labels: {
            //         usePointStyle: true,
                    
                    
            //       },
            // },
            legend: {
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  generateLabels: function(chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                      return data.labels.map((label, i) => {
                        const meta = chart.getDatasetMeta(0);
                        const ds = data.datasets[0];
                        const arc = meta.data[i];
                        const value = ds.data[i];
                        const labelCount = filteredData.gender[i].count;
                        return {
                          text: `${label} - ${labelCount}`,
                          fillStyle: ds.backgroundColor[i],
                          strokeStyle: ds.borderColor[i],
                          lineWidth: ds.borderWidth,
                          hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                          index: i
                        };
                      });
                    }
                    return [];
                  }
                }
              },
            title: {
                display: true,
                text: 'Gender',
                position: 'bottom',
            },
        },
        cutout: '70%',
    };
   
  const pieData = {
    labels: labels,
    datasets: [
      {
        label: 'Count',
        data: filteredData?.gender
          ? filteredData.gender.map((item) => item.count)
          : [],
        backgroundColor: [
            'rgb(99, 102, 241)',
            'rgb(236, 72, 153)',
          
        ],
        borderColor: [
            'rgb(99, 102, 241,1)',
            'rgb(236, 72, 153,1)',
         
        ],
        borderWidth: 1,
      },
    ],
  };
  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: (chart) => {
        const { ctx, chartArea } = chart;
        const { width, height } = chartArea;

        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';

        const total = chart.data.datasets.reduce((acc, dataset) => {
            return acc + dataset.data.reduce((dAcc, d) => dAcc + d, 0);
        }, 0);

        const text = total.toLocaleString();
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillStyle = '#000';
        ctx.fillText(text, textX, textY);

        ctx.save();
    },}

  return <Doughnut data={pieData} options={options} plugins={[centerTextPlugin]}/>;
}
