import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { Underline, ErrorText } from "../Styles/PageStyles";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaBookOpen } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaMale, FaFemale } from "react-icons/fa";
import { IoIosMale, IoIosFemale } from "react-icons/io";
import { TbUserDollar } from "react-icons/tb";
const DashboardSummary = ({ data }) => {
  //if (user.isAdmin)
  const cards = data?.data?.data?.card;
  const [summaryData, setSummaryData] = useState({
    totalCustomers: "Total Customers",
    todayCustomers: "New Customers (1 Day)",
    totalCourses: "Total Courses",
    subscriptionMonthly: "Subscriptions",
    // subscriptionWeekly: "Subscriptions (1 Week)",
    subscriptionDaily: "Subscriptions (1 Day)",
    male: "Male",
    female: "Female",
    paidUser: "Paid Customers"
  });

  return (
    <BookingSummary>
      {/* <Title>Today's Summary</Title> */}
      <BookingGrid>
        <BoxComp
          value={cards[0].count}
          title={summaryData.totalCustomers}
          className="bg-amber-50 text-amber-400 border-b-amber-400"
          icon={<BsFillPeopleFill size={"100%"} />}
        />
        <BoxComp
          value={cards[1].count}
          title={summaryData.todayCustomers}
          className="bg-blue-50 text-blue-400 border-b-blue-400"
          icon={<BsFillPeopleFill size={"100%"} />}
        />
        <BoxComp
          value={cards[6].count}
          title={summaryData.male}
          className="bg-cyan-50 text-cyan-400 border-b-cyan-400"
          icon={<IoIosMale size={"100%"} />}
        />
        <BoxComp
          value={cards[7].count}
          title={summaryData.female}
          className="bg-indigo-50 text-indigo-400 border-b-indigo-400"
          icon={<IoIosFemale size={"100%"} />}
        />
        <BoxComp
          value={cards[2].count}
          title={summaryData.totalCourses}
          className="bg-fuchsia-50 text-fuchsia-400 border-b-fuchsia-400"
          icon={<FaBookOpen size={"100%"} />}
        />
        <BoxComp
          value={cards[3].count}
          title={summaryData.subscriptionMonthly}
          className="bg-rose-50 text-rose-400 border-b-rose-400"
          icon={<AiFillDollarCircle size={"100%"} />}
        />
        {/* <BoxComp
          value={cards[4].count}
          title={summaryData.subscriptionWeekly}
          className="bg-cyan-50 text-cyan-400 border-b-cyan-400"
          icon={<AiFillDollarCircle size={"100%"} />}
        /> */}
        <BoxComp
          value={cards[5].count}
          title={summaryData.subscriptionDaily}
          className="bg-violet-50 text-violet-400 border-b-violet-400"
          icon={<AiFillDollarCircle size={"100%"} />}
        />
        <BoxComp
          value={cards[8].count}
          title={summaryData.paidUser}
          className="bg-lime-50 text-lime-400 border-b-lime-400"
          icon={<TbUserDollar size={"100%"} />}
        />
        {/* <BoxComp value={data?.totalCalls} title={summaryData.all} className="bg-teal-500 text-white" /> */}
      </BookingGrid>
    </BookingSummary>
  );
};

const BoxComp = ({ value, title, className, icon }) => (
  <Box className={`border-b-4 shadow-xl ${className} `}>
    <BoxTitle>
      {title.trim()}
      <hr className={`${className} w-1/3 border-b-4 rounded-full`} />
    </BoxTitle>

    <BoxCount>
      <div
        className={`absolute bottom-0 left-1/2 translate-x-[-50%] opacity-10 ${className}`}
      >
        {icon}
      </div>
      <p>{value || 0}</p>{" "}
    </BoxCount>
  </Box>
);

const BookingSummary = tw.div``;
const Title = tw.h1`xl:text-2xl lg:text-md text-sm  text-gray-800   font-medium`;
const SubTitle = tw.h4`text-sm text-gray-100 mt-1.5`;

const BookingGrid = tw.div`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12 gap-8 lg:gap-6 text-center`;
const Box = tw.div` w-full min-h-80 mb-10 rounded-lg shadow-md flex flex-col p-4 justify-center items-center relative hover:scale-105 transition `;
const BoxTitle = tw.h3`text-sm sm:text-md lg:text-lg font-medium text-left absolute inset-4 uppercase text-zinc-500 font-semibold`;
const BoxCount = tw.p`font-bold xl:text-[72px] text-[48px] `;

export default DashboardSummary;
