import tw from "tailwind-styled-components";

import Loading from "../Loading";

import Config from "../../Config";

import Model from "../Model";
import { SubmitBtn } from "../Styles/InputStyles";
import { useNavigate } from "react-router-dom";

const RoomContainsOptions = ({
  setRoomUser,
  selectedData,
  roomEntryLoading,
  clientId,
}) => {
  const Navigate = useNavigate();

  const redirectToBedPages = () => {
    
    Navigate(`/beds/${selectedData.roomId}`);
  };

  const redirectToAddTenantPage=()=>{

    Navigate(`/addTenant/${selectedData.roomOption.propId}/${selectedData.roomId}/${clientId}`,{state:{"rent":selectedData.roomOption.rent}})
  }

  console.log(clientId)
  return (
    <Model width={`w-[400px]`} setOpenModel={setRoomUser}>
      <Title>
        Room Number{" "}
        <span className="text-primary-color"> {selectedData?.roomNum} </span>
      </Title>

      <Wrapper>
        <p
          htmlFor="name"
          className="mb-1 text-xs md:text-sm font-semibold text-gray-700"
        >
          Room Type{" "}
          <span className="text-primary-color">
            {" "}
            {selectedData?.roomOption?.name}{" "}
          </span>
        </p>
        <p
          htmlFor="name"
          className="mb-1 text-xs md:text-sm font-semibold text-gray-700"
        >
          Total Beds{" "}
          <span className="text-primary-color">
            {" "}
            {selectedData?.roomOption?.totalBedCount}{" "}
          </span>
        </p>
        <p
          htmlFor="name"
          className="mb-1 text-xs md:text-sm font-semibold text-gray-700"
        >
          Occupied Beds{" "}
          <span className="text-primary-color">
            {" "}
            {selectedData?.bedOccupied}{" "}
          </span>
        </p>
        {roomEntryLoading && <Loading />}
        {!roomEntryLoading && (
          <div>
            <div className="flex flex-wrap gap-2 mt-6">
              <p className="bg-gray-800 text-gray-200 rounded-md px-3 py-1 text-xs md:text-sm">
                Rent: ₹{selectedData?.roomOption?.rent}
              </p>
              <p className="bg-gray-800 text-gray-200 rounded-md px-3 py-1 text-xs md:text-sm">
                Type: {Config.roomTypeMapping[selectedData?.roomOption?.type]}
              </p>
            </div>
            <div className="flex flex-wrap gap-2 mt-6">
              {selectedData?.roomOption?.amenities
                ?.split(",")
                .map((amenity, index) => (
                  <span
                    key={index}
                    className="bg-primary-color text-white rounded-full px-3 py-1 text-xs md:text-sm flex gap-2"
                  >
                    {amenity.trim()}
                  </span>
                ))}
            </div>
            <div>
              <BtnWrapper className="flex justify-center items-center">
                <SubmitBtn
                  type="button"
                  disabled={
                    selectedData.roomOption.totalBedCount -
                      selectedData.bedOccupied ===
                    selectedData.roomOption.totalBedCount
                  }
                  onClick={redirectToBedPages}
                >
                  View Tenants
                </SubmitBtn>
                <SubmitBtn
                  onClick={redirectToAddTenantPage}
                  type="button"
                  disabled={
                    !(
                      selectedData.roomOption.totalBedCount -
                        selectedData.bedOccupied >
                      0
                    )
                  }
                >
                  Add Tenants
                </SubmitBtn>
              </BtnWrapper>
            </div>
          </div>
        )}
      </Wrapper>
    </Model>
  );
};

const Wrapper = tw.div`mt-3 mb-3`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
const BtnWrapper = tw.div`w-full space-x-10 mt-8`;
export default RoomContainsOptions;
