import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const getGender = (selectedData) => {
    if (selectedData.gender == 1) {
      return <>Male</>;
    } else if (selectedData.gender == 2) {
      return <>Female</>;
    }
  }; 

  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;;
    } else if (status === 2) {
      return <Requested>Requested</Requested>;
    } else if(status === 3) {
      return <Occupied>Occupied</Occupied>;
    }
    else if(status === 4) {
      return  <Moving>Moving Out</Moving>;
    }
    else {
      return <Reserved>Reserved</Reserved>;
    }
  };


  return (
<Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
  <Wrapper>
  <Title><MdViewHeadline className="mt-1" />View Details</Title>
  <SingleItem name={"Property ID"} value={selectedData?.propId || "N/A"} />
  <SingleItem name={"Room ID"} value={selectedData?.roomId || "N/A"} />
  <SingleItem name={"Owner Name"} value={selectedData?.clientName || "N/A"} />
  <SingleItem name={"Tenant Name"} value={selectedData?.tenantName || "N/A"} />
  <SingleItem name={"Floor"} value={selectedData?.floor || "N/A"} />
  <SingleItem name={"Number of Beds"} value={selectedData?.bedCount || "N/A"} />
  <SingleItem name={"Rental Cycle"} value={selectedData?.rentalCycle || "N/A"} />
  <SingleItem name={"Rent"} value={selectedData?.rent || "N/A"} />
  <SingleItem name={"Security Deposit"} value={selectedData?.security || "N/A"} />
  <SingleItem name={"Maintenance Fee"} value={selectedData?.maintenance || "N/A"} />
  <SingleItem name={"Notice Period"} value={selectedData?.noticePeriod || "N/A"} />
  <SingleItem name={"Lock-In Period"} value={selectedData?.lockInPeriod || "N/A"} /> 
  <SingleItem name={"Agreement Period"} value={selectedData?.agreementPeriod || "N/A"} />
  <SingleItem name={"Status"} value={getStatus(selectedData?.status) || "N/A"} />
  <SingleItem name={"Agreement Start Date"} value={selectedData?.agreementStartDate ? new Date(selectedData.agreementStartDate).toLocaleDateString() : "N/A"} />
  <SingleItem name={"Move In Date"} value={selectedData?.moveInDate ? new Date(selectedData.moveInDate).toLocaleDateString() : "N/A"} />
  <SingleItem name={"Move Out Date"} value={selectedData?.moveOutDate ? new Date(selectedData.moveOutDate).toLocaleDateString() : "N/A"} />
  <SingleItem name={"Created At"} value={selectedData?.createdAt ? new Date(selectedData.createdAt).toLocaleString() : "N/A"} />
  <SingleItem name={"Updated At"} value={selectedData?.updatedAt ? new Date(selectedData.updatedAt).toLocaleString() : "N/A"} />
</Wrapper>
</Model> 
  ); 
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>
      {(name === "Rent" || name === "Security Deposit" || name === "Maintenance Fee") && (value != 'N/A') && <span className="mr-1">₹</span>}
      {value}
      {(name === "Grace Period" || name === "Agreement Period" || name === "Notice Period" || name === "Lock-In Period") && (value != 'N/A') && <span className="ml-1">months</span>}
    </Value> 
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Requested = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Occupied= tw.div`bg-orange-100 text-orange-700 text-xs border-orange-300 border w-24 h-7 flex items-center justify-center rounded`;
const Reserved = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded`;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
const Moving = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded`;

export default ViewDetailModel;
