import React, { useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BedIcon from "../../Components/AddTenants/BedIcon";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TenantForm from "../../Components/AddTenants/TenantForm";
import RoomDetailForm from "../../Components/AddTenants/RoomDetailForm";

const AddTenant = () => {
  const { roomId, propId, clientId } = useParams();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedBed, setSelectedBed] = useState(undefined);
  const [tenantData, setTenantData] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  
  const location = useLocation();

  console.log(location);

  const fetchVacantBeds = async () => {
    if (roomId) {
      const body = new FormData();
      body.append("roomId", roomId);
      let response = await axios.post(`${Config.apiUrl}/getvecantbed`, body, {
        headers: {
          authorization: "Bearer " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
      return response;
    }
  };

  const vacantBedData = useQuery({
    queryKey: ["vacantBed"],
    queryFn: fetchVacantBeds,
  });

  const postTenantData = async (values) => {
    if (selectedBed) {
      const body = new FormData();

      values.rentalCycle = values.rentalCycle.slice(5);
      body.append("clientId", clientId);
      body.append("roomId", roomId);
      body.append("propId", propId);
      body.append("bedId", selectedBed);
      const keys = Object.keys(values);
      keys.map((i) => {
        body.append(i, values[i]);
      });

      const tenantKeys = Object.keys(tenantData);
      tenantKeys.map((i) => {
        body.append(i, tenantData[i]);
      });

      for (let i of body.entries()) {
        console.log(i[0], i[1]);
      }

      let response = await axios
        .post(`${Config.apiUrl}/addtenant`, body, {
          headers: {
            authorization: "Bearer " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((res) => {
          toastDataSuccess();
        })
        .catch((err) => {
          if (err.response.status === 409) {
            console.log(err.response);
            toastDataAlreadyExist(err.response.data.msg);
          }
        });

      return response;
    } else {
      toastSelectBed();
    }
  };

  const toastDataSuccess = () =>
    toast.success("Tenant Added Successfully ", {
      hideProgressBar: false,
    });

  const toastDataAlreadyExist = (data) =>
    toast.warn(data, {
      hideProgressBar: false,
    });

  const toastSelectBed = () =>
    toast.warn("Please Select Bed Id", {
      hideProgressBar: false,
    });

  const steps = ["Tenant Form", "Room Form"];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    setCompleted({
      ...completed,
      [activeStep]: true,
    });
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Select Bed")}</Title>
              <Underline />
            </div>
          </div>
          <div className="flex items-end flex-wrap gap-5 mt-8">
            {vacantBedData?.data?.data &&
              (vacantBedData?.data?.data?.beds).map((i) => (
                <BedIcon
                  id={i.id}
                  setSelectedBed={setSelectedBed}
                  selectedBed={selectedBed}
                />
              ))}
          </div>

          {/* {vacantBedData?.data?.data?.beds?.length > 0 && (
            <Accordion className="mt-8 mb-2" defaultExpanded={true}>
              <AccordionSummary
                id="panel-header"
                aria-controls="panel-content"
                expandIcon={<ArrowDropDownIcon className="" />}
              >
                <div className="flex w-full justify-between items-center">
                  <div>
                    <Title className="">{t("Tenant Form")}</Title>
                    <Underline className="" />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <TenantForm setTenantData={setTenantData} />
              </AccordionDetails>
            </Accordion>
          )}
          {tenantData === undefined ? (
            <span className="text-primary-color pt-2 pb-2 animate-pulse">
              * Please fill the above form to procced
            </span>
          ) : null}
          {vacantBedData?.data?.data?.beds?.length > 0 && (
            <Accordion
              className="mb-8 mt-2"
              disabled={tenantData === undefined ? true : false}
              defaultExpanded={tenantData !== undefined ? true : false}
            >
              <AccordionSummary
                id="panel-header1"
                aria-controls="panel-content1"
                expandIcon={<ArrowDropDownIcon />}
              >
                <div className="flex w-full justify-between items-center">
                  <div>
                    <Title>{t("Room Form")}</Title>
                    <Underline />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <RoomDetailForm
                  selectedBed={selectedBed}
                  postTenantData={postTenantData}
                />
              </AccordionDetails>
            </Accordion>
          )} */}

          <Box sx={{ width: "100%" }} className="mt-8">
            <Box sx={{width:"50%"}}>
            <Stepper nonLinear activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit">
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            </Box>
            <div>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                    Step {activeStep + 1}
                    {activeStep === 0 ? (
                      <TenantForm setTenantData={setTenantData} setActiveStep={setActiveStep} />
                    ) : (
                      <RoomDetailForm
                        selectedBed={selectedBed}
                        postTenantData={postTenantData}
                      />
                    )}
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    {activeStep===0?<Button sx={{ mr: 1 }} form="tenant-form" type="submit">
                      Next
                    </Button>:<Button sx={{ mr: 1 }} form="room-detail-form" type="submit">
                      Submit
                    </Button>}
                  </Box>
                </React.Fragment>
              )}
            </div>
          </Box>
        </BoxContainer>
      </Bg>
    </>
  );
};

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

export default AddTenant;
