import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {

  const getStatus = (status) => {
    if (status === 1) {
      return <Pending>Pending</Pending>;
    } else if (status === 2) {
      return <Completed>Completed</Completed>;
    } else if (status === 3) {
      return <Active>Active</Active>;
    } else if (status === 4) {
      return <Inactive>Inactive</Inactive>;
    } else {
      return 'Invalid Status';
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
        <SingleItem name={"Get Id"} value={selectedData?.gId} />
        <SingleItem name={"Name"} value={selectedData?.name} />
        <SingleItem name={"Owner Name"} value={selectedData?.ownerName} />
        <SingleItem name={"Phone Number"} value={selectedData?.ownerMobile} />
        <SingleItem name={"Address"} value={selectedData?.address} />
        <SingleItem name={"Street Address"} value={selectedData?.streetAddress} />
        <SingleItem name="Fine" value={selectedData?.fine} />
        <SingleItem name="Security" value={selectedData?.security} />
        <SingleItem name="Grace Period" value={selectedData?.gracePeriod} />
        <SingleItem name="Agreement Period" value={selectedData?.agreementPeriod} />
        <SingleItem name="Notice Period" value={selectedData?.noticePeriod} />
        <SingleItem name="Lock-In Period" value={selectedData?.lockInPeriod} />
        <SingleItem name="Status" value={getStatus(selectedData?.status)} />
        <SingleItem name={"Type"} value={selectedData?.type} />
        <SingleItem name={"Floor Count"} value={selectedData?.floorCount} />
        <SingleItem name="Is Ground Included" value={selectedData?.isGroundIncluded} />
        <SingleItem name="Rental Cycle" value={selectedData?.rentalCycle} />
        <SingleItem name="Bank ID" value={selectedData?.bankId} />
        <SingleItem name="Police Verification Enabled" value={selectedData?.isPoliceVerificationEnabled} />
        <SingleItem name="ID Verification Enabled" value={selectedData?.isIdVerificationEnabled} />
        <SingleItem name={"Created At"} value={moment(selectedData?.created_at).format("DD-MM-YYYY hh:mm A")} />
        <SingleItem name={"Updated At"} value={moment(selectedData?.updated_at).format("DD-MM-YYYY hh:mm A")} />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>
      {(name === "Security" || name === "Fine" || name === "Maintenance Fee") && <span className="mr-1">₹</span>}
      {value}
      {(name === "Grace Period" || name === "Agreement Period" || name === "Notice Period" || name === "Lock-In Period") && <span className="ml-1">months</span>}
    </Value>
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Active = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const Pending = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
const Completed = tw.div`bg-lime-100 text-lime-700 text-xs border-lime-300 border w-24 h-7 flex items-center justify-center rounded`;
const Inactive = tw.div`bg-red-100 text-red-700 text-xs border-red-300 border w-24 h-7 flex items-center justify-center rounded`;
export default ViewDetailModel;

