import React from "react";
import tw from "tailwind-styled-components";
import Images from "../Images";

const Model = ({ width, setOpenModel, children }) => {
  const closeModel = () => setOpenModel(false);

  const getCurrentTarget = (e) => {
    const targetClass = Array.from(e.target.classList);
    if (targetClass.includes("addCommentModel")) closeModel();
  };

  return (
    <Wrapper onClick={getCurrentTarget}>
      <Box $width={width} style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <Cross src={Images.CrossBlack} alt="cross" onClick={closeModel} />
        {children}
      </Box>
    </Wrapper>
  );
};

const Wrapper = tw.div`
fixed top-0 higher-z-index right-0 bottom-0 left-0 bg-neutral-900 backdrop-blur-sm bg-opacity-70 sm:px-3  h-screen flex justify-center items-center  z-10 addCommentModel overflow-y-auto`;

const Box = tw.div`
${(p) => (p.$width ? ` bg-white ${p.$width} ` : " bg-white w-11/12 lg:w-3/5")}
 mx-auto h-auto  rounded-md px-6 py-4 relative `;

const Cross = tw.img`
absolute top-5 right-5 w-3.5 z-10 cursor-pointer`;

export default Model;
