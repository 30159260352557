import React from "react";
import tw from "tailwind-styled-components"; 
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const getGender = (selectedData) => {
    if (selectedData.gender == 1) {
      return <>Male</>;
    } else if (selectedData.gender == 2) {
      return <>Female</>;
    }
    else {
      return <>both</>;
    }
  }; 
  
  const getStatus = (status) => {
    if (status === 1) {
      return <Unverified>Unverified</Unverified>;
    } else if (status === 2) {
      return <Pending>Pending</Pending>;
    } else if(status === 3) {
      return <Vaccines>Vacant</Vaccines>;
    }
    else if(status === 4) {
      return <Requested>Requested</Requested>;
    }
    else if (status === 5) {
      return <Occupied>Occupied</Occupied>;
    }
    else {
      return <Moving>Moving Out</Moving>
    }
  };

  const getKycStatus = (kycStatus) => {
    if (kycStatus === 1) {
      return <KycPending>Pending</KycPending>;
    } else if (kycStatus === 2) {
      return <IdUpload>ID Uploaded</IdUpload>;
    } else if (kycStatus === 3) {
      return <Personal>PInfo Submitted</Personal>;
    } else if (kycStatus === 4) {
      return <Selfie>Selfie Uploaded</Selfie>
    } else if (kycStatus === 5) {
      return <RentAgreement>Rent Agreemented</RentAgreement>
    } else if (kycStatus === 6) {
      return <Verification>Police Verified</Verification>
    } else {
      return 'Unknown Status';
    }
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
<Title><MdViewHeadline className="mt-1" />View Details</Title>
<SingleItem name={"Id"} value={selectedData?.id} />
<SingleItem name={"Name"} value={selectedData?.name} />
<SingleItem name={"Phone Number"} value={selectedData?.mobile} />
<SingleItem name={"Gender"} value={getGender(selectedData?.gender) || "N/A"} />
<SingleItem name={"Occupation"} value={selectedData?.occupation || "N/A"} />
<SingleItem name={"Status"} value={getStatus(selectedData?.status) || "N/A"} />
<SingleItem name={"Address"} value={selectedData?.address || "N/A"} />
<SingleItem name={"Date of Birth"} value={moment(selectedData?.dob).format("DD-MM-YYYY") || "N/A"} />
<SingleItem name={"Father's Name"} value={selectedData?.fatherName || "N/A"} />
<SingleItem name={"Created At"} value={moment(selectedData?.createdAt).format("DD-MM-YYYY hh:mm A")} />
<SingleItem name={"Updated At"} value={moment(selectedData?.updatedAt).format("DD-MM-YYYY hh:mm A")} />
<SingleItem name={"KYC Status"} value={getKycStatus(selectedData?.kycStatus) || "N/A"} />
<SingleItem name={"Property Name"} value={selectedData?.propertyName || "N/A"} />
<SingleItem name={"Register Id"} value={selectedData?.regId || "N/A"} />
<SingleItem name={"Email"} value={selectedData?.email || "N/A"} />
<SingleItem name={"Alternate Mobile"} value={selectedData?.alternateMobile || "N/A"} />
      </Wrapper>
    </Model> 
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const Requested = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const Occupied= tw.div`bg-teal-100 text-emerald-600 text-xs grid font-bold place-items-center rounded-lg px-2 py-1 w-24 h-7`;
const Unverified = tw.div`bg-rose-100 text-rose-600 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const Vaccines = tw.div`bg-green-100 text-green-600 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const Pending = tw.div`bg-blue-100 text-blue-500 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const Moving = tw.div`bg-orange-100 text-orange-500 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const IdUpload = tw.div`bg-green-100 text-green-600 font-bold text-xs grid place-items-center rounded-md h-7 w-36`;
const Personal = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border flex items-center justify-center rounded-md h-7 w-36`;
const Selfie =  tw.div`bg-green-100 text-green-600 font-bold text-xs grid place-items-center rounded-md  mr-4 h-7 w-36`;
const RentAgreement = tw.div`bg-teal-100 text-emerald-600 text-xs grid font-bold place-items-center rounded-md h-7 w-36`;
const Verification = tw.div`bg-yellow-200 text-yellow-800 text-xs flex items-center justify-center rounded-md h-7 w-36`;
const KycPending = tw.div`bg-blue-100 text-blue-500 font-bold text-xs grid place-items-center rounded-md h-7 w-36`;

export default ViewDetailModel; 