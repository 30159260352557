import tw from "tailwind-styled-components";

export const InputGroup = tw.div`w-full flex flex-col space-y-1.5`;

export const CombineInputGroup = tw.div`w-full flex flex-row space-x-4 items-end`;

export const FieldWrapper = tw.div`
${(p) => (p.$select ? "h-10" : "pl-2   field-wrapper border border-gray-300")}
 relative  rounded-md  w-full  flex items-center `;

export const Label = tw.label`text-sm text-gray-500 font-normal`;

export const FormContainer = tw.div` grid max-w-2xl gap-5 md:gap-7 mt-4`;

export const FormContainerVertical = tw.div` grid  gap-5 md:gap-7 mt-4`;

export const SubmitBtn = tw.button`
text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-primary-color hover:bg-hover-color text-white rounded-md shadow-md`;

export const PreviewBtn = tw.button`
text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-cyan-100 hover:bg-cyan-200 text-cyan-700 rounded-md shadow-md`;

export const OtherBtn = tw.button`
  text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-green-100 hover:bg-green-200 text-gray-600 rounded-md shadow-md`;

export const PrevBtn = tw.button`
  text-sm  w-32  md:w-32 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-gray-200 hover:bg-gray-100 text-gray-600 rounded-md shadow-md`;

export const SaveBtn = tw.button`
  text-sm  w-32  md:w-32 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-cyan-100 hover:bg-cyan-200 text-cyan-700 rounded-md shadow-md`;

export const SmallBtn = tw.button`
  whitespace-nowrap text-xs px-4 py-2 sm:px-6 sm:py-2.5 rounded-md bg-white-400  cursor-pointer hover:bg-gray-200 shadow border transition duration-200 text-gray-600 sm:text-sm`;

export const CancelBtn = tw.button`
  text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-gray-100 hover:bg-gray-200 text-gray-600 rounded-md shadow-md`;