import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";

const EditPropertie = ({
  editPropertieMutate,
  editEntryLoading, 
  setEditUser,
  selectedData, 
}) => { 
  
  const InitialValues = {
    name: selectedData?.name, 
    mobile: selectedData?.ownerMobile,
    status: selectedData?.status,
    id: selectedData?.id,
  }; 

  const SubmitHandler = (values) => { 
    editPropertieMutate(values); 
  }  

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Properties</Title> 

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler} enableReinitialize>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>

              <InputGroup>
                  <Label htmlFor="mobile">Phone</Label>
                  <FieldWrapper className="bg-gray-100">
                    <Field
                      type="text"
                      name="mobile"
                      id="mobile" 
                      disabled
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper> 

                </InputGroup>

                <InputGroup>
                  <Label htmlFor="status">Status</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="status" id="status" autoComplete="off">
                      {(props) => (
                        <Select
                          className="w-full h-full pl-2"
                          {...props.field}
                        >
                          <MenuItem value="1">Pending</MenuItem>
                          <MenuItem value="2">Completed</MenuItem>
                          <MenuItem value="3">Active</MenuItem>
                          <MenuItem value="4">Inactive</MenuItem>
                        </Select>
                      )}
                    </Field> 
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <InputGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper> 
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    className="truncate"
                  />
                </FieldWrapper>
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditPropertie;

