import React, { useEffect, useState } from "react";
import { Chart } from 'chart.js/auto';
import { ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip, Legend);


export function Doughnuts({ data }) {
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data?.data);
    }
  }, [data?.data?.data]);

  const labels = filteredData?.planType
    ? filteredData.planType.map((item) => item.name)
    : [];

  const counts = filteredData?.planType
    ? filteredData.planType.map((item) => item.count)
    : [];

  const total = counts.reduce((acc, cur) => acc + cur, 0);

  const pieData = {
    labels: labels,
    datasets: [
      {
        label: 'Count',
        data: counts,
        backgroundColor: [
          'rgb(205,127,50)',
          'rgb(192,192,192)',
          'rgb(255,215,0)',
        ],
        borderColor: [
          'rgb(205,127,50,1)',
          'rgb(192,192,192,1)',
          'rgb(255,215,0,1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      // legend: {
      //   position: 'right',
        
      //   labels: {
      //     usePointStyle: true,
         
          
      //   },
      // },
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          generateLabels: function(chart) {
            const data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label, i) => {
                const meta = chart.getDatasetMeta(0);
                const ds = data.datasets[0];
                const arc = meta.data[i];
                const value = ds.data[i];
                const labelCount = filteredData.planType[i].count;
                return {
                  text: `${label} - ${labelCount}`,
                  fillStyle: ds.backgroundColor[i],
                  strokeStyle: ds.borderColor[i],
                  lineWidth: ds.borderWidth,
                  hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                  index: i
                };
              });
            }
            return [];
          }
        }
      },
      title: {
        display: true,
        text: 'Plan purchase according to type',
        position: 'bottom',
      },
      // tooltip: {
      //   callbacks: {
      //     label: function (context) {
      //       let label = context.label || '';
      //       if (label) {
      //         label += ': ';
      //       }
      //       const percentage = Math.round((context.parsed / total) * 100);
      //       label += percentage + '%';
      //       return label;
      //     }
      //   }
      // }
    },
    cutout: '70%',
    responsive: true,
    
  };
  
  const centerTextPlugin = {
    id: 'centerText',
    beforeDraw: (chart) => {
        const { ctx, chartArea } = chart;
        const { width, height } = chartArea;

        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';

        const total = chart.data.datasets.reduce((acc, dataset) => {
            return acc + dataset.data.reduce((dAcc, d) => dAcc + d, 0);
        }, 0);

        const text = total.toLocaleString();
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillStyle = '#000';
        ctx.fillText(text, textX, textY);

        ctx.save();
    },}

  return <Doughnut data={pieData} options={options} plugins={[centerTextPlugin]}/>;
}
