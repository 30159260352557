import { useFormik } from "formik";
import * as Yup from "yup";

const TenantForm = ({setTenantData,setActiveStep}) => {
    const occupationArray = ["Select Value", "Professional", "Student"];
  
    const kyc_status = [
      "Select a value",
      "Pending",
      "ID Uploaded",
      "Personal Info",
      "Photo Uploaded",
      "Rent Agreement Done",
      "Police Verification Done",
    ];
    const IntialTenantValues = {
      name: "",
      mobile: "",
      gender: "",
      kycStatus: "",
      occupation: "",
    };
    
    const TenantFormSchema = Yup.object().shape({
      name: Yup.string().required("Enter Name"),
      mobile: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter Correct Phone Number")
      .max(10, "Enter Correct Phone Number"),
      gender: Yup.string().required("Select Gender"),
      kycStatus: Yup.string()
      .required("Select KYC Status")
      .matches(/^[1-9]+$/, "Select KYC Status")
      .max(1, "Select KYC Status")
      .min(1, "Select KYC Status"),
      occupation: Yup.string().required("Select Occupation"),
    });
    
    const tenantFormik = useFormik({
      initialValues: IntialTenantValues,
      validationSchema: TenantFormSchema,
      onSubmit: (values) => {
        setTenantData(values);
        setActiveStep(1)
      },
    });
    return (
      <form
        onSubmit={tenantFormik.handleSubmit}
        className="flex items-end flex-wrap gap-3 w-full"
        id="tenant-form"
      >
        <div className="w-full flex gap-5 mt-8">
          {/* TENANT NAME */}
          <div className="flex-row justify-start items-center w-full">
            <lable htmlFor="floor" className="text-lg text-primary-color">
              Tenant Name
            </lable>
            <input
              type="text"
              name="name"
              id="tenantName"
              className="border border-primary-color rounded w-full text-lg"
              placeholder="Enter Full Name"
              onChange={tenantFormik.handleChange}
              onBlur={tenantFormik.handleBlur}
            />
            <div className="text-rose-500">
              {tenantFormik.touched.name && tenantFormik.errors.name}
            </div>
          </div>
          {/* PHONE NUMBER */}
          <div className="flex-row justify-start items-center w-full">
            <lable htmlFor="floor" className="text-lg text-primary-color">
              Phone No.
            </lable>
            <input
              type="text"
              name="mobile"
              id="mobile"
              className="border border-primary-color rounded w-full text-lg"
              placeholder="Enter Phone No. "
              onChange={tenantFormik.handleChange}
              onBlur={tenantFormik.handleBlur}
            />
            <div className="text-rose-500">
              {tenantFormik.touched.mobile && tenantFormik.errors.mobile}
            </div>
          </div>
        </div>
        <div className="w-full flex gap-5">
          {/* GENDER */}
          <div className="flex justify-start items-center w-full gap-5">
            <label className="text-lg text-primary-color">Gender</label>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                name="gender"
                value="1"
                onChange={tenantFormik.handleChange}
              />{" "}
              <label>Male</label>
              <input
                type="radio"
                name="gender"
                value="2"
                onChange={tenantFormik.handleChange}
              />{" "}
              <label>Female</label>
            </div>
            <div className="text-rose-500">
              {tenantFormik.touched.gender && tenantFormik.errors.gender}
            </div>
          </div>
          {/* KYC STATUS */}
          <div className="flex-row justify-start items-center w-full">
            <lable htmlFor="lockInPeriod" className="text-lg text-primary-color">
              KYC Status
            </lable>
            <select
              className="text-lg border-primary-color rounded border w-full"
              onChange={tenantFormik.handleChange}
              onBlur={tenantFormik.handleBlur}
              name="kycStatus"
            >
              {kyc_status.map((item, key) => (
                <option key={key} value={key}>
                  {item}
                </option>
              ))}
            </select>
            <div className="text-rose-500">
              {tenantFormik.touched.kycStatus && tenantFormik.errors.kycStatus}
            </div>
          </div>
        </div>
        <div className="w-full flex gap-5">
          {/* OCCUPATION */}
          <div className="flex-row justify-start items-center w-full">
            <lable htmlFor="occupation" className="text-lg text-primary-color">
              Occupation
            </lable>
            <select
              className="text-lg border-primary-color rounded border w-full"
              name="occupation"
              onChange={tenantFormik.handleChange}
              onBlur={tenantFormik.handleBlur}
            >
              {occupationArray.map((item, key) => (
                <option key={key} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <div className="text-rose-500">
              {tenantFormik.touched.occupation && tenantFormik.errors.occupation}
            </div>
          </div>
        </div>
      
      </form>
    );
  };

  export default TenantForm;