import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Model from "../Model";

const availableAmenities = [
  { label: "Balcony", value: "balcony" },
  { label: "Ac", value: "ac" },
  { label: "Tv", value: "tv" },
  { label: "Attached Bathroom", value: "attached bathroom"},
  { label: "Wi-Fi", value: "wi-fi" },
  { label: "Washing Machine", value: "washing machine"},
];

const EditRoomOptions = ({
  editOwnerMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  const amenitiesArray = selectedData?.amenities
    ? selectedData.amenities.split(",").map((amenity) => ({
        label: amenity.trim(),
        value: amenity.trim().toLowerCase(),
      }))
    : [];

  const animatedComponents = makeAnimated();

  const InitialValues = {
    name: selectedData?.name,
    rent: selectedData?.rent,
    amenities: amenitiesArray,
    id: selectedData?.id,
  };

  const SubmitHandler = (values) => {
    const formattedValues = {
      ...values,
      amenities: values.amenities.map((item) => item.label).join(", "),
    };
    editOwnerMutate(formattedValues);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Room Options</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler} enableReinitialize>
          {({ values, setFieldValue }) => {

            // Dynamically filter available amenities based on current selections
            const filteredAmenities = availableAmenities.filter(
              (amenity) => !values.amenities.some((selected) => selected.value === amenity.value)
            );

            return (
              <Form>
                <Wrapper>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="name">Name</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="rent">Rent</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="rent"
                          id="rent"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <InputGroup>
                    <Label htmlFor="amenities">Amenities</Label>
                    <Select
                      isMulti
                      name="amenities"
                      components={animatedComponents}
                      options={filteredAmenities}
                      value={values.amenities}
                      onChange={(selectedOptions) => setFieldValue("amenities", selectedOptions)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                      menuPlacement="auto"
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />  
                  </InputGroup>
                </Wrapper>
                <BtnWrapper>
                  <SubmitBtn type="submit">Update</SubmitBtn>
                </BtnWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`grid gap-6 my-6 px-1`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

export default EditRoomOptions;
