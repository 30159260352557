import React, { useState } from "react";
// import tw from "tailwind-styled-components";

// import makeAnimated from "react-select/animated";

// import { useNavigate } from "react-router-dom";

import RoomContainsOptions from "./RoomContainsOptions";
import RoomDoesNotContainsOptions from "./RoomDoesNotContainsOptions";




//Add new Data to floorData
const RoomFloors = ({
  roomOwnerMutate,
  roomEntryLoading,
  setRoomUser,
  selectedData,
  floorData,
  data,
  roomOptions,
}) => {
  console.log(selectedData,floorData,data,roomOptions)
  //const navigate = useNavigate();
  //const [showDropdowns, setShowDropdowns] = useState(false);
  //const [selectedFloorItem, setSelectedFloorItem] = useState(null);

  // const goToBeds = (selectedData) => {
  //   navigate(`/beds/${selectedData}`);
  // };

  // Extract amenities as an array from selectedFloorItem
  // const amenitiesArray = selectedFloorItem?.amenities
  //   ? selectedFloorItem.amenities.split(",").map((amenity) => {
  //       return {
  //         label: amenity.trim(),
  //         value: amenity.trim().toLowerCase(),
  //       };
  //     })
  //   : [];

  // const animatedComponents = makeAnimated();

  // const InitialValues = {
  //   id: selectedFloorItem?.id,
  //   propId: selectedFloorItem?.propId,
  //   name: selectedFloorItem?.name,
  //   amenities: amenitiesArray,
  //   rent: selectedFloorItem?.rent || "",
  //   roomOption: selectedFloorItem?.type || "",
  // };

  // Form submission handler
  // const SubmitHandler = (values) => {
  //   const formattedValues = {
  //     ...values,
  //     amenities: values.amenities.map((item) => item.label).join(", "),
  //   };

  //   roomOwnerMutate(formattedValues);
  // };
  // const handleDropdownSelect = () => {
  //   setShowDropdowns(true);
  // };
  console.log(data)
  return (
    <>
      {selectedData?.roomOptionId ? (
        <RoomContainsOptions
          setRoomUser={setRoomUser}
          selectedData={selectedData}
          roomEntryLoading={roomEntryLoading}
          clientId={data.clientId}
        />
      ) : (
        <RoomDoesNotContainsOptions
          setRoomUser={setRoomUser}
          selectedData={selectedData}
          roomEntryLoading={roomEntryLoading}
          apiData={data}
          roomOptions={roomOptions}
        />
      )}
    </>
  );
  // return (
  //   <Model width={`w-[400px]`} setOpenModel={setRoomUser}>
  //     <Title>Room Option</Title>
  //     {roomEntryLoading && <Loading />}
  //     {!roomEntryLoading && (
  //       <Formik
  //         initialValues={InitialValues}
  //         onSubmit={SubmitHandler}
  //         enableReinitialize
  //       >
  //         {({ values, setFieldValue }) => {
  //           // Filter available amenities to exclude already selected amenities
  //           const filteredAmenities = availableAmenities.filter(
  //             (amenity) =>
  //               !values.amenities.some(
  //                 (selected) => selected.value === amenity.value
  //               )
  //           );
  //           return (
  //             <Form>

  //               <Wrapper>
  //                 <p
  //                   htmlFor="name"
  //                   className="mb-1 text-xs md:text-sm font-semibold text-gray-700"
  //                 >
  //                   Room Type
  //                 </p>
  //                 <FieldWrapper $select={true}>
  //                   <Field name="name" id="name">
  //                     {({ field }) => (
  //                       <MUISelect
  //                         {...field}
  //                         fullWidth
  //                         // className="!ml-2"
  //                         onChange={(e) => {
  //                           const selectedIndex = e.target.value - 1;
  //                           setSelectedFloorItem(
  //                             floorData.data.data[selectedIndex]
  //                           );
  //                           field.onChange(e);
  //                           handleDropdownSelect();
  //                         }}
  //                       >
  //                         {Array.isArray(floorData?.data?.data) ? (
  //                           floorData.data.data.map((item, index) => (
  //                             <MenuItem key={index} value={index + 1}>
  //                               {item.name || "Unknown Sharing"}
  //                             </MenuItem>
  //                           ))
  //                         ) : (
  //                           <MenuItem>No floor data available</MenuItem>
  //                         )}
  //                       </MUISelect>
  //                     )}
  //                   </Field>
  //                 </FieldWrapper>
  //                 {showDropdowns && (
  //                   <div>
  //                     <div className="flex flex-wrap gap-2 mt-6">
  //                       <p className="bg-gray-800 text-gray-200 rounded-md px-3 py-1 text-xs md:text-sm">
  //                         Rent: ₹{selectedFloorItem?.rent}
  //                       </p>
  //                       <p className="bg-gray-800 text-gray-200 rounded-md px-3 py-1 text-xs md:text-sm">
  //                         Type:{" "}
  //                         {Config.roomTypeMapping[selectedFloorItem?.type]}
  //                       </p>
  //                     </div>
  //                     <div className="flex flex-wrap gap-2 mt-6">
  //                       {selectedFloorItem?.amenities
  //                         ?.split(",")
  //                         .map((amenity, index) => (
  //                           <span
  //                             key={index}
  //                             className="bg-primary-color text-white rounded-full px-3 py-1 text-xs md:text-sm"
  //                           >
  //                             {amenity.trim()}
  //                           </span>
  //                         ))}
  //                     </div>
  //                   </div>
  //                 )}
  //               </Wrapper>
  //               <BtnWrapper>
  //                 <SubmitBtn type="submit">Submit</SubmitBtn>
  //               </BtnWrapper>
  //             </Form>
  //           );
  //         }}
  //       </Formik>
  //     )}
  //   </Model>
  // );
};

// const Wrapper = tw.div`mt-3 mb-3`;
// const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl text-gray-700 font-bold text-left`;
// const BtnWrapper = tw.div`w-full space-x-10 mt-8`;

export default RoomFloors;
