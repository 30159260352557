import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const RoomDetailForm = ({ selectedBed, postTenantData}) => {
  const toastSelectBed = () =>
    toast.warn("Please Select Bed Id", {
      hideProgressBar: false,
    });
  const location = useLocation();

  const addTenantSchema = Yup.object().shape({
    floor: Yup.string().max(10).required("Floor Required"),
    rent: Yup.number().required("Rent required"),
    rentalCycle: Yup.string().required("Start date is required"),
    noticePeriod: Yup.string().required("Notice period Required"),
    lockInPeriod: Yup.string().required("Lock in Period Required"),
    agreementPeriod: Yup.string().required("Agreement Period Required"),
    security: Yup.number().required("Secuirty is Required"),
    agreementStartDate: Yup.string().required("Start date is required"),
    moveInDate: Yup.string().required("Start date is required"),
    // .matches(
    //   /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/,
    //   "Date must be in MM/DD/YYYY format"
    // ),
    maintenance: Yup.string().required("Enter Maintainance Fees"),
  });

  const InitialValue = {
    maintenance: "",
    floor: "",
    rent: location?.state?.rent,
    security: "",
    rentalCycle: "",
    noticePeriod: "",
    lockInPeriod: "",
    agreementPeriod: "",
    agreementStartDate: "",
    moveInDate: "",
  };

  const formik = useFormik({
    initialValues: InitialValue,
    validationSchema: addTenantSchema,
    onSubmit: (values, { resetForm }) => {
      selectedBed ? postTenantData(values) : toastSelectBed();
    },
  });

  const months = [
    "Select a Value",
    "1",
    "1.5",
    "2",
    "2.5",
    "3",
    "3.5",
    "4",
    "4.5",
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5",
    "8",
    "8.5",
    "9",
    "9.5",
    "10",
    "10.5",
    "11",
    "11.5",
    "12",
  ];
  return (
    <form onSubmit={formik.handleSubmit} id="room-detail-form">
      {/* FLOOR */}
      <div className="w-full flex gap-5">
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="floor" className="text-lg text-primary-color">
            Floor{" "}
          </lable>
          <input
            type="text"
            name="floor"
            id="floor"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter Floor"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.floor && formik.errors.floor}
          </div>
        </div>
        {/* RENT */}
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="rent" className="text-lg text-primary-color">
            Rent{" "}
          </lable>
          <input
            type="text"
            name="rent"
            value={formik.values.rent}
            disabled={true}
            id="rent"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter Rent"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.rent && formik.errors.rent}
          </div>
        </div>
      </div>
      {/* SECURITY */}
      <div className="w-full flex gap-5">
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="security" className="text-lg text-primary-color">
            Security{" "}
          </lable>
          <input
            type="text"
            name="security"
            id="security"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter Security"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.security && formik.errors.security}
          </div>
        </div>
        {/* RENTAL CYCLE */}
        <div className="flex-row justify-start items-center w-full relative">
          <lable htmlFor="rentalCycle" className="text-lg text-primary-color">
            Rental Cycle
          </lable>
          <input
            type="date"
            name="rentalCycle"
            id="rentalCycl"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter Rental cycle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.rentalCycle && formik.errors.rentalCycle}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5">
        {/* MOVE IN DATE */}
        <div className="flex-row justify-start items-center w-full relative">
          <lable htmlFor="moveInDate" className="text-lg text-primary-color">
            Move In Date
          </lable>
          <input
            type="date"
            name="moveInDate"
            id="moveInDate"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter Rental cycle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.moveInDate && formik.errors.moveInDate}
          </div>
        </div>
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="floor" className="text-lg text-primary-color">
            maintenance{" "}
          </lable>
          <input
            type="text"
            name="maintenance"
            id="maintenance"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter maintenance Amount"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.maintenance && formik.errors.maintenance}
          </div>
        </div>
      </div>

      <div className="w-full flex gap-5">
        {/* AGREEMENT START DATE */}
        <div className="flex-row justify-start items-center w-full relative">
          <lable
            htmlFor="agreementStartDate"
            className="text-lg text-primary-color"
          >
            Agreement Start Date
          </lable>
          <input
            type="date"
            name="agreementStartDate"
            id="agreementStartDate"
            className="border border-primary-color rounded w-full text-lg"
            placeholder="Enter Agreement Start Date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="text-rose-500">
            {formik.touched.agreementStartDate &&
              formik.errors.agreementStartDate}
          </div>
        </div>
        {/* AGREEMENT PERIOD */}
        <div className="flex-row justify-start items-center w-full">
          <lable
            htmlFor="agreementPeriod"
            className="text-lg text-primary-color"
          >
            Agreement Period
          </lable>
          <select
            className="text-lg border-primary-color rounded border w-full"
            name="agreementPeriod"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {months.map((item, key) => (
              <option key={key}>{item}</option>
            ))}
          </select>
          <div className="text-rose-500">
            {formik.touched.agreementPeriod && formik.errors.agreementPeriod}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5">
        {/* NOTICE PERIOD */}

        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="noticePeriod" className="text-lg text-primary-color">
            Notice Period
          </lable>
          <select
            className="text-lg border-primary-color rounded border w-full"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="noticePeriod"
          >
            {months.map((item, key) => (
              <option key={key}>{item}</option>
            ))}
          </select>
          <div className="text-rose-500">
            {formik.touched.noticePeriod && formik.errors.noticePeriod}
          </div>
        </div>
        {/* LOCKIN PERIOD */}
        <div className="flex-row justify-start items-center w-full">
          <lable htmlFor="lockInPeriod" className="text-lg text-primary-color">
            Lock In Period
          </lable>
          <select
            className="text-lg border-primary-color rounded border w-full"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="lockInPeriod"
          >
            {months.map((item, key) => (
              <option key={key}>{item}</option>
            ))}
          </select>
          <div className="text-rose-500">
            {formik.touched.lockInPeriod && formik.errors.lockInPeriod}
          </div>
        </div>
      </div>
      <div className="w-full flex gap-5"></div>
      
    </form>
  );
};

export default RoomDetailForm;
